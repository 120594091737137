import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import { AnalyticsProvider } from "./analytics";
import analyticsConfig from './config/analyticsConfig'
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";
import "./assets/fonts/stylesheet.css";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AnalyticsProvider {...analyticsConfig}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </AnalyticsProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
