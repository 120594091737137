import React from "react";

const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 512 512"
    width="24"
    height="24"
    fill="red"
  >
    <g>
      <g>
        <path d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M343.586,315.302L284.284,256l59.302-59.302c7.81-7.81,7.811-20.473,0.001-28.284c-7.812-7.811-20.475-7.81-28.284,0    L256,227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.81,20.474,0.001,28.284L227.716,256    l-59.302,59.302c-7.811,7.811-7.812,20.474-0.001,28.284c7.813,7.812,20.476,7.809,28.284,0L256,284.284l59.303,59.302    c7.808,7.81,20.473,7.811,28.284,0C351.398,335.775,351.397,323.112,343.586,315.302z" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const SuccessIcon = () => (
  <svg
    viewBox="0 0 26 26"
    width="24"
    height="24"
    fill="green"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        className="circle"
        d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
      />
      <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
    </g>
  </svg>
);

const alertStyle = {
  backgroundColor: "#111",
  color: "white",
  padding: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  width: "300px",
  boxSizing: "border-box",
};

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div id={'thank-you-message'} style={{ ...alertStyle, ...style }}>
      {options.type === "success" && <SuccessIcon  />}
      {options.type === "error" && <ErrorIcon />}
      <p className="alert--text">{message}</p>
    </div>
  );
};

export default AlertTemplate;
