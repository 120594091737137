import React, { useState } from "react";
import loadable from '@loadable/component'

import "./App.css";

const Header = loadable(()=>import('./components/Header'));
const HeroContainer = loadable(()=>import('./components/HeroContainer'));
const BodyContainer = loadable(()=>import('./components/BodyComponent'));
const Footer = loadable(()=>import('./components/Footer'));



function App() {
  const [open, setOpen] = useState(false);
  return (
    <div className="App">
      <Header open={open} setOpen={setOpen} />
      <main className="main">
        <HeroContainer open={open} />
        <BodyContainer />
      </main>
      <Footer />
    </div>
  );
}

export default App;
