import React, { useEffect, useMemo } from "react";
import { useRouter } from "../hooks/useRouter";

import ReactPixel from "react-facebook-pixel";

import ReactGA from "react-ga";


var Chance = require("chance");
var chance = new Chance();

export const generateUserId = () => {
  return `${new Date().valueOf()}${chance.fbid()}`;
};

export const Context = React.createContext({});

export const AnalyticsProvider = ({
  facebookPixel = null,
  googleAnalytics = null,
  yandexMetrika = null,
  context = Context,
  children
}) => {
    const router = useRouter();
    const pathname = router ? router.pathname : null;

    const content = useMemo(()=>(
    <context.Provider
      value={{
        facebookPixel,
        googleAnalytics,
        trigger: (action, data) => {
          try {
            if (googleAnalytics)
              ReactGA.event({
                category: "actions",
                action,
                value: data ? data.value : undefined
              });
            if (facebookPixel) ReactPixel.trackCustom(action, data);
          } catch (error) {
            console.error(error);
          }
        }
      }}
    >
      {children}
    </context.Provider>
  ),[facebookPixel, googleAnalytics,children])

  useEffect(() => {

    if (!localStorage.getItem("_analyticsUserId")) {
    
      localStorage.setItem("_analyticsUserId", generateUserId());
    }
    if (facebookPixel) {
      console.log(facebookPixel,'facebookPixel')
      const facebookPixelAdvancedMatching = {
        userId: localStorage.getItem("_analyticsUserId")
      };
      ReactPixel.init(facebookPixel, facebookPixelAdvancedMatching, {
        autoConfig: true,
        debug: false
      });
    }
    if (googleAnalytics) {
      ReactGA.initialize(googleAnalytics, {
        gaOptions: {
          userId: localStorage.getItem("_analyticsUserId")
        }
      });
    }

    return ()=>{}
  });

  useEffect(() => {
    if (!process.browser || !pathname) return content;

    if (facebookPixel) ReactPixel.pageView();
    if (googleAnalytics) {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    }
    return ()=>{}
  }, [pathname, facebookPixel, googleAnalytics, content]);

  if (!process.browser || !pathname) return content;

  return (
    <>
      {content}
    </>
  );
};
